@if (isVisible()) {
    <div
        class="validation-errors d-flex align-items-start gap-2"
        data-testid="validation-error-container"
    >
        <ul
            class="mb-0"
            [ngClass]="{ 'single-error': hasSingleError() }"
        >
            @for (error of errors() | keyvalue; track error.key) {
                <li aria-live="polite">
                    @switch (error.key) {
                        @case ('required') {
                            {{ 'VALIDATION.required' | translate }}
                        }
                        @case ('EXPIRY_DATE_REQUIRED') {
                            {{ 'VALIDATION.EXPIRY_DATE_REQUIRED' | translate }}
                        }
                        @case ('DELIVERY_DATE_REQUIRED') {
                            {{
                                'VALIDATION.DELIVERY_DATE_REQUIRED' | translate
                            }}
                        }
                        @case ('INSPECTION_CODE_INVALID') {
                            {{
                                'VALIDATION.INSPECTION_CODE_INVALID' | translate
                            }}
                        }
                        @case ('invalidPickupCode') {
                            {{ 'VALIDATION.PICKUP_CODE_INVALID' | translate }}
                        }
                        @case ('maxlength') {
                            {{
                                'VALIDATION.MAX_LENGTH'
                                    | translate
                                        : {
                                              requiredMaxLength:
                                                  error.value.requiredLength,
                                          }
                            }}
                        }
                        @case ('minlength') {
                            {{
                                'VALIDATION.MIN_LENGTH'
                                    | translate
                                        : {
                                              requiredMinLength:
                                                  error.value.requiredLength,
                                          }
                            }}
                        }
                        @case ('duplicate') {
                            {{ 'VALIDATION.DUPLICATE' | translate }}
                        }
                        @case ('isBlacklistedDomain') {
                            {{ 'VALIDATION.IS_BLACKLISTED_DOMAIN' | translate }}
                        }
                        @case ('isNotWhitelisted') {
                            {{ 'VALIDATION.IS_NOT_WHITELISTED' | translate }}
                        }
                        @case ('email') {
                            {{ 'VALIDATION.EMAIL.pattern' | translate }}
                        }
                        @case ('phone') {
                            {{
                                'VALIDATION.PHONE.LEADING_0_NEEDED' | translate
                            }}
                        }
                        @case ('slug') {
                            {{ 'VALIDATION.SLUG' | translate }}
                        }
                        @case ('pattern') {
                            {{ 'VALIDATION.PATTERN' | translate }}
                        }
                        @case ('maxservice') {
                            {{ 'VALIDATION.MAX_SERVICE' | translate }}
                        }
                        @case ('min') {
                            {{
                                'VALIDATION.UNDER_MIN_WITH_VAL'
                                    | translate: { min: error.value.min }
                            }}
                        }
                        @case ('max') {
                            {{
                                'VALIDATION.OVER_MAX_WITH_VAL'
                                    | translate: { max: error.value.max }
                            }}
                        }
                        @case ('lessEqualThan') {
                            {{
                                'VALIDATION.LESS_EQUAL_THAN'
                                    | translate
                                        : {
                                              referenceField:
                                                  error.value.referenceField,
                                          }
                            }}
                        }
                        @case ('phoneLeadingZeroPattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.PHONE_LEADING_ZERO'
                                    | translate
                            }}
                        }
                        @case ('addressPattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.ADDRESS'
                                    | translate
                            }}
                        }
                        @case ('cityPattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.CITY'
                                    | translate
                            }}
                        }
                        @case ('postalCodePattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.POSTAL_CODE'
                                    | translate
                            }}
                        }
                        @case ('europePostalCodePattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.EUROPE_POSTAL_CODE'
                                    | translate
                            }}
                        }
                        @case ('serviceBikeSerialNumberPattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.GENERATED_BIKE_SERIAL_NUMBER_IS_FORBIDDEN'
                                    | translate
                            }}
                        }
                        @case ('invalid') {
                            {{ 'VALIDATION.INVALID' | translate }}
                        }
                        @case ('validateEqual') {
                            {{
                                'VALIDATION.PASSWORDS_ARE_NOT_EQUAL' | translate
                            }}
                        }
                        @case ('passwordPattern') {
                            {{ 'VALIDATION.PASSWORD_PATTERN' | translate }}
                        }
                        @case ('ibanPattern') {
                            {{
                                'VALIDATION.CUSTOM_PATTERN.IBAN_PATTERN'
                                    | translate
                            }}
                        }
                        @case ('equalString') {
                            {{
                                'VALIDATION.EQUAL_STRING'
                                    | translate
                                        : {
                                              targetString:
                                                  error.value.targetString,
                                          }
                            }}
                        }
                        @case ('isAfter') {
                            {{
                                'VALIDATION.IS_AFTER'
                                    | translate
                                        : {
                                              maxDate:
                                                  error.value.referenceDate
                                                  | date,
                                          }
                            }}
                        }
                        @case ('isBefore') {
                            {{
                                'VALIDATION.IS_BEFORE'
                                    | translate
                                        : {
                                              minDate:
                                                  error.value.referenceDate
                                                  | date,
                                          }
                            }}
                        }
                        @case ('invalidParkDate') {
                            {{
                                'INSURANCE.CREATE.INVALID_PARK_TIME_VALIDATION_ERROR'
                                    | translate
                            }}
                        }
                        @case ('employeeNotFound') {
                            {{ 'VALIDATION.EMPLOYEE_NOT_FOUND' | translate }}
                        }
                        @case ('isFuture') {
                            {{
                                'VALIDATION.IS_FUTURE'
                                    | translate
                                        : {
                                              maxDate:
                                                  error.value.referenceDate
                                                  | date,
                                          }
                            }}
                        }
                        @case ('passwordContainsName') {
                            {{
                                'VALIDATION.PASSWORD_CONTAINS_NAME' | translate
                            }}
                        }
                        @default {
                            {{ error.value }}
                        }
                    }
                </li>
            }
        </ul>
        @if (showErrorIcon()) {
            <i
                data-testid="validation-error-icon"
                class="fas fa-exclamation-triangle"
                aria-hidden="true"
            ></i>
        }
    </div>
}
